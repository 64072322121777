import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import styled from "styled-components"
import Header from "../components/header_blog"
import Footer from "../components/footer_blog"
import Img from "gatsby-image"

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 5% auto 5%;
  grid-template-rows: 10vh auto 7%;
  @media only screen and (min-width: 650px) {
    /* For everything bigger than 768px */
    grid-template-rows: 10vw auto 7%;
  }
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`

const Title = styled.h1`
  margin-top: 6%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    margin-top: 2vh;
  }
`

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

  return (
    <MainContainer>
      <Header />
      <div style={{ gridArea: "2 / 2 / 3/ 3" }}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={data.avatar.childImageSharp.sizes.src}
          slug={pageContext.slug}
          date={post.frontmatter.date}
        />
        <article>
          <header>
            <Title> {post.frontmatter.title}</Title>
            <Img fluid={featuredImgFluid} />
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
              {post.frontmatter.date}
            </p>
          </header>
          <section
            className="blog"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <Bio />
          </footer>
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
              marginBottom: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
      <Footer />
    </MainContainer>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/witold_LI.jpg/" }) {
      childImageSharp {
        sizes(maxWidth: 1200) {
          src
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
