/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import SocialBar from "./socialbar"
import styled from "styled-components"

import { rhythm } from "../utils/typography"

const BarWrapper = styled.div`
  margin-bottom: 5%;
  @media only screen and (min-width: 768px) {
    padding-left: 20%;
    padding-right: 20%;
  }
`
const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic-sm.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div>
      <div
        style={{
          display: `flex`,
          marginBottom: "2%",
          justifyContent: "center",
        }}
      >
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author.name}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <p style={{ marginBottom: 0 }}>
          Written by <strong>{author.name}</strong>, {author.summary}
          . <br />
          <b style={{ fontWeight: 700 }}>Let's stay in touch </b>
          <b>through your preferred platform:</b>
        </p>
      </div>
      <BarWrapper>
        <SocialBar />
      </BarWrapper>
    </div>
  )
}

export default Bio
