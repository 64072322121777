import React from "react"
import styled, { ThemeProvider } from "styled-components"

import { Link } from "gatsby"
import { theme } from "../theme"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header = styled.div`
  border-bottom: 5px solid cyan;
  grid-area: 1 / 1 / 2 / 4;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    /*     background-color: red;
 */
    border-bottom: none;
  }
`

const MidContainer = styled.div`
  border-bottom: 5px magenta solid;
  height: 100%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    border-bottom: none;
  }
`
const InternalContainer = styled.div`
  display: grid;
  grid-template-columns: 25% auto 25%;
  grid-template-rows: 100%;
  border-bottom: 5px solid yellow;
  height: 100%;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    /*     background-color: red;
 */
    grid-template-columns: 23% auto 23%;
    grid-template-rows: 100%;
    border-bottom: none;
  }
`

const TitleContainer = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  height: 100%;
  /*   background-color: cyan;
 */
  @media only screen and (min-width: 768px) {
    grid-area: 1 / 3 / 2 / 4;
    padding-left: 0%;
    padding-right: 21%;
  }
`

const SvgPort = styled.svg`
  height: 100%;
  display: flex;

  @media only screen and (min-width: 768px) {
    margin-top: 5%;
  }
`

const MainText = styled.text`
  font: 300 1.5em League;
`
const StyledLink = styled(Link)`
  grid-area: 1 / 2 / 2 / 3;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
  @media only screen and (min-width: 650px) {
    /* For everything bigger than 768px */
    /*     background-color: red;
*/
    padding-left: 23%;
    padding-right: 24%;
  }
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    /*     background-color: red;
*/
    padding-left: 0%;
    padding-right: 0%;
    grid-area: 1 / 3 / 2 / 4;
  }
`

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <Header>
        <MidContainer>
          <InternalContainer>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
                gridArea: `1 / 1 / 2 / 2`,
                display: "flex",
                alignItems: "center",
                marginLeft: "21%",
                fontSize: "2rem",
              }}
              to={`/blog`}
            >
              <FontAwesomeIcon style={{ marginTop: "5%" }} icon={faArrowLeft} />
            </Link>
            <StyledLink to={`/`}>
              <TitleContainer>
                <SvgPort viewBox="0 0 93 15">
                  <MainText x="0" y="15">
                    Raúl Berganza
                  </MainText>
                </SvgPort>
              </TitleContainer>
            </StyledLink>
          </InternalContainer>
        </MidContainer>
      </Header>
    </ThemeProvider>
  )
}
